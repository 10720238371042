import routes, {HOME_ROUTE_NAME} from '@routes/config';
export default [
    {
        path: '',
        redirect: HOME_ROUTE_NAME,
        component: () => import( /* webpackChunkName: "App" */ '@views/App'),
        meta: {
            auth: true
        },
        children: [
            {
                path: routes.landing.home,
                name: routes.landing.home,
                component: () => import(/* webpackChunkName: "LandingHome" */ '@components/Home')
            },
            {
                path: routes.landing.resetpassword,
                name: routes.landing.resetpassword,
                component: () => import(/* webpackChunkName: "ResetPassword" */ '@components/ResetPassword')
            },
            {
                path: routes.landing.about,
                name: routes.landing.about,
                component: () => import(/* webpackChunkName: "About" */ '@components/About')
            },
            {
                path: routes.landing.products,
                name: routes.landing.products,
                component: () => import(/* webpackChunkName: "Products" */ '@components/Products')
            },
            {
                path: routes.landing.contact,
                name: routes.landing.contact,
                component: () => import(/* webpackChunkName: "Contact" */ '@components/Contact')
            },
            {
                path: routes.landing.my_account,
                name: routes.landing.my_account,
                component: () => import(/* webpackChunkName: "MyAccount" */ '@components/MyAccount')
            },
            {
                path: routes.landing.user_identity,
                name: routes.landing.user_identity,
                component: () => import(/* webpackChunkName: "UserIdentity" */ '@components/UserIdentity')
            },
            {
                path: routes.landing.my_orders,
                name: routes.landing.my_orders,
                component: () => import(/* webpackChunkName: "MyOrders" */ '@components/MyOrders')
            },
            {
                path: routes.landing.my_orders_history,
                name: routes.landing.my_orders_history,
                component: () => import(/* webpackChunkName: "MyOrdersHistory" */ '@components/MyOrdersHistory')
            },
            {
                path: routes.landing.faq,
                name: routes.landing.faq,
                component: () => import(/* webpackChunkName: "FAQ" */ '@components/Faq')
            },
            {
                path: routes.landing.product,
                name: routes.landing.product,
                component: () => import(/* webpackChunkName: "Product" */ '@components/Product')
            },
            {
              path: routes.landing.my_profile,
              name: routes.landing.my_profile,
              component: () => import(/* webpackChunkName: "My_Profile" */ '@components/user/Profile')
            },
            {
              path: routes.landing.lubricant_dealers,
              name: routes.landing.lubricant_dealers,
              component: () => import(/* webpackChunkName: "LubricantDealers" */ '@components/LubricantDealers')
            },
            {
              path: 'profile',
              name: routes.user.profile,
              component: () => import(/* webpackChunkName: "Profile" */ '@components/user/Profile')
            },
            {
              path: routes.user.index,
              name: routes.user.index,
              component: () => import(/* webpackChunkName: "User" */ '@components/user/Index')
            },
        ]
    },
];
