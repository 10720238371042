<template v-if="left">
  <span class="px-3" v-bind="$attrs" v-on="$listeners">{{Verification_Timer}}</span>
</template>

<script>
import moment         from "moment";
import DatatableMixin from "@mixins/DatatableMixin";
export default {
  name: 'Timer',
  mixins: [DatatableMixin],
  props:{
    left: {
      type: Number,
      default: () => 0
    },
  },
  data() {
    return {
      timeLeft: 0,
      timer: null,
      Verification_Timer: '',
    };
  },
  methods:{
    TimerRun(){
      this.timeLeft = Number(600-this.left);
      this.timer = setInterval(() => {
        if (this.timeLeft <= 0) {
          clearInterval(this.timer);
          this.Verification_Timer = '';
        }
        else {this.timeLeft=this.timeLeft-1;}
      }, 1000);
      if(this.timeLeft <= 0){
        console.log('done');
      }
    },
  },
  watch:{
    timeLeft: function (val) {
      if(val>=0) {
        const timeZero = moment("1900-01-01 00:00:00").locale('en');
        this.Verification_Timer = timeZero.add(val, 'seconds').format("mm:ss")
      }
      else{
        this.Verification_Timer='';
      }
    },
  },
  mounted() {
    this.TimerRun();
  }
};
</script>
