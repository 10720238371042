export default {
  q: '؟',
  sar: 'SAR',
  false: 'معطل',
  true: 'مفعل',
  progress: 'التقدم...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'تسجيل الدخول',
  home: 'الرئيسية',
  max_price: 'أعلى سعر',
  min_price: 'أقل سعر',
  select_def: 'فرز حسب',
  select_priceLTH: 'السعر: الأدنى إلى الأعلى',
  select_priceHTL: 'السعر: الأعلى إلى الأدنى',
  select_odoLTH: 'عداد الكيلوات: الأدنى إلى الأعلى',
  select_odoHTL: 'عداد الكيلوات: الأعلى إلى الأدنى',
  select_createdOTN: 'الأقدم إلى الأحدث',
  select_createdNTO: 'الأحدث إلى الأقدم',
  car_list: 'قائمة السيارات',
  products: 'المنتجات',
  about: 'من نحن',
  contact: 'اتصل بنا',
  faq: 'الأسئلة الشائعة',
  lubricantdealer: 'موزعي الزيوت',
  faq_string1: 'فيما يلي الأسئلة المتكررة التي يطرحها عملاؤنا.',
  our_cars: 'سياراتنا',
  my_account: 'حسابي',
  my_orders: 'طلباتي الفعالة',
  my_orders_history: 'طلباتي المغلقة',
  account_information: "بيانات الحساب",
  identity_information: "إدارة الهويات",
  personal_information: "البيانات الشخصية",
  language: 'لغة العرض',
  done: 'موافق',
  logout: 'تسجيل الخروج',
  cancel: 'إلغاء',
  yes: 'نعم',
  no: 'لا',
  enabled: 'مفعل',
  disabled: 'معطل',
  save: 'حفظ',
  save_password: 'تحديث كلمة المرور',
  change: 'تعديل',
  options: 'خيارات',
  create: 'إضافة',
  store: 'إضافة',
  update: 'تعديل',
  destroy: 'حذف',
  upload: 'رفع',
  click_to_upload: 'انقر هنا لرفع ',
  delete: 'حذف',
  show: 'عرض',
  view: 'عرض',
  control: 'تحكم',
  title: 'العنوان',
  none: 'لا يوجد',
  na: 'بدون',
  export: 'تصدير',
  close: 'إغلاق',
  edit: 'تعديل',
  add: 'إضافة',
  register: 'تسجيل جديد',
  print: 'طباعة',
  today: 'اليوم',
  day: 'اليوم',
  date: 'التاريخ',
  now: 'الآن',
  am: 'ص',
  pm: 'م',
  error: 'خطأ !!',
  search: 'بحث',
  exportPdf: 'تصدير البيانات PDF',
  exportExcel: 'تصدير البيانات Excel',
  ImportExcel: 'استيراد البيانات من Excel',
  printContractPDF: 'طباعةالعقد/PDF',
  printBillPDF: 'طباعةالفاتورة/PDF',
  refreshTable: 'تحديث الصفحة',
  expansionPanelHeaderOptions: 'الأدوات',
  close_window: 'اغلاق النافذة',
  year: 'سنة',
  showDetails: 'عرض التفاصيل',
  Details: 'تفاصيل ',
  information: 'معلومات ',
  car: 'السيارة',
  car_name: 'اسم السيارة',
  car_manufacture: 'الشركة المصنعة',
  car_year: 'سنة الصنع',
  car_km: 'عداد الكيلو مترات',
  car_chassie: 'رقم الهيكل',
  car_out_color: 'اللون الخارجي',
  car_in_color: 'اللون الداخلي',
  car_fuel_type: 'نوع الوقود',
  order: 'اطلب',
  show_order: 'تفاصيل الطلب',
  show_notificaion: 'تفاصيل الإشعار',
  car_order: 'اطلب السيارة',
  car_order1: 'سيصبح بامكانك طلب هذه المركبة في: ',
  car_to: 'إلى',
  car_photo_show: 'عرض مكبر للمحتوى',
  cant_order: 'لطلب المركبة يجب عليك تفعيل رقم جوالك وأن يحتوي حسابك على هوية واحدة على الأقل' +
    ' مفعلة',
  down_payment: 'الدفعة المقدمة',
  last_payment: 'الدفعة الأخيرة',
  vat: 'الضريبة',
  setting: 'الضبط',
  month: 'شهر',
  non_defined: 'غير محدد',
  undefined: 'غير محدد',
  greg_letter: ' م',
  hijri_letter: ' هـ',
  import: 'استيراد',
  qty: "الكمية",
  price: "السعر",
  view_filters: "عوامل التصفية",
  total: "الاجمالي",
  the_total: "اجمالي",
  down_with_vat: "الدفعة المقدمة شامل الضريبة",
  brokerage_with_vat: "رسوم الوساطة مع الضريبة",
  brokerage_services: 'رسوم الوساطة',
  sr: " ريال",
  bill_number: "رقم الفاتورة",
  contract_number: "رقم العقد",
  without: "بدون",
  rest: "المتبقي",
  note: "ملاحظه: ",
  created_at: 'تاريخ الإضافة',
  updated_at: 'تاريخ التعديل',
  deleted_at: 'تاريخ الحذف',
  created_by: 'أضيف بواسطة',
  updated_by: 'التعديل بواسطة',
  deleted_by: 'حذف بواسطة',
  twitter:   'تويتر',
  facebook:  'فيس بوك',
  instagram: 'انستاجرام',
  snapchat:  'سناب شات',
  telegram:  'تلي قرام',
  youtube:  'يوتيوب',
  copyright: 'جميع الحقوق محفوظة لمتجر القاسم',
  first: 'البداية',
  last: 'النهاية',
  previous: 'السابق',
  next: 'التالي',
  latest: 'اهلاً بكم',
  get_latest_updates: 'احصل على آخر التحديثات.',
  my_profile: 'الملف الشخصي',
  follow_on_snapchat: 'تابعونا على سناب شات',
  follow_on_telegram: 'تابعونا على التليقرام',
  special_vehicles: 'بمتجر  القاسم للسيارات',
  browse_by_brands: 'تصفح السيارات حسب الماركة',
  multiple_vehicles_types: 'سيارات متوفرة في فئات مختلفة',
  hyundai: 'هونداي',
  description: 'مواصفات اضافية',
  kia: 'كيا',
  how_we_make_it_done: 'طريقة عمل القاسم للمركبات المستوردة',
  you_might_also_like: 'قد يعجبك أيضاً',
  verify_email: 'تفعيل البريد الإلكتروني!!!',
  verify_mobile: 'تفعيل الجوال!!!',
  verify_email_title: 'تفعيل البريد الإلكتروني',
  verify_approve_title: 'كود الموافقة على شروط العقد',
  verify_mobile_title: 'تفعيل رقم الجوال',
  not_verified_email: 'البريد الإلكتروني غير مفعل!!!',
  not_verified_mobile: 'رقم الجوال غير مفعل!!!',
  verify_mobile_text1: 'تم إرسال كود التفعيل الى الجوال: ',
  verify_email_text1: 'تم إرسال كود التفعيل الى اليريد الإلكتروني: ',
  verify_mobile_text2: 'يرجى إدخال كود التفعيل من الرسالة النصية: ',
  verify_email_text2: 'يرجى إدخال كود التفعيل من رسالة اليريد الإلكتروني: ',
  verify_mobile_text3: 'يمكنك طلب كود تفعيل جديد في حال عدم استلامك لكود التفعيل خلال: ',
  verify_email_text3: 'يمكنك طلب كود تفعيل جديد في حال عدم استلامك لكود التفعيل خلال: ',
  verify_email_text4: ' يجعلك على اطلاع دائم باخر العروض والمركبات التي يتم عرضها بمتجرنا',
  upload_identity_photo: 'رفع صورة الهوية',
  BankRecipt: 'ايصال التحويل البنكي',
  upload_BankRecipt_photo: 'رفع ايصال التحويل البنكي',
  bank_details: 'المعلومات البنكية لطلب المرتجع',
  selectAll: 'اختر الكل',
  contact_name1:'عبد المجيد بن قاسم',
  contact_name2:'عبد المجيد بن قاسم',
  contact_name3:'ابراهيم',
  contact_owner:'المالك',
  contact_korea:'جمهورية كوريا الجنوبية',
  contact_sa:'المملكة العربية السعودية',
  contact_address1:'الدائري الجنوبي، الحزم، الرياض 14963',
  contact_address2:'Ingwon-ro, Yeonsu-gu, Incheon, South Korea',
  product_subscription:'اشترك ليصلك عبر الإيميل عند توفر المركبة',
  product_no_subscription:'مشترك بالتبليغ البريدي اضغط لإلغاء الإشتراك',
  step_1:'اختياراتنا',
  step_2:'مشترياتنا',
  step_3:'عروضنا',
  step_4:'إتمام الشراء',
  step_5:'الشحن والإستلام',
  step_6:'المبيعات الى دول العالم',
  step_1_string:'يقوم فريقنا بالمشاركة يوميا من الاثنين الى الجمعه بمزادات السيارات بكوريا الجنوبيه وتلبية رغبات الأسواق المحليه واختيار ماهو مناسب ومطلوب لشراءه',
  step_2_string:'بعد شراء المركبات ( في حال توفرها ) من المزادات يقوم فريقنا بتشييك ماهو لازم بالصيانه واحصاء تكاليف كل مركبه\t\t\t\t\t\t',
  step_3_string:'بعد التشييك يقوم فريق العمل بتصوير المركبات بكامل تفاصيلها من عيوب ومميزات وتوضيحها ومن ثم عرض المركبه للبيع في هذا الموقع\t\t\t\t\t\t',
  step_4_string:'بعد اختياركم للمركبة المناسبه من المعروض وطلب الحجز سيتم استقطاع جزء من مبلغ المركبه كعربون ويدخل العميل بياناته الكامله ويستلم العميل فاتورة الشراء ورقم الطلب وسيتم التواصل مع العميل لإستكمال باقي الإجراءات',
  step_5_string:'بعد انهاء إجراءات المركبه في كوريا بإسم المشتري سيتم الحجز لها في اقرب باخره للمملكة العربية السعوديه وسيتم فتح قروب واتساب بعد ابحار الباخره بأسبوع وضم بالقروب ملّاك المركبات المشحونه واعطائهم تعليمات التفويض الجمركي لمخلصنا بالمملكة واطّلاع المشتري على روابط تتبع البواخر ومتابعة العمل حتى استلام العميل لمركبته سواء بالدمام او شحنها بواسطة نقليات البسامي الى مدينته',
  step_6_string:'اما في حال رغبة المشتري للشراء الى دولة أخرى غير المملكة العربيه السعوديه يتم تسجيل الطلب في خانة طلباتي وسيتم التوفير للطلب بشكل شخصي ولكل دولة سعر ونظام من ناحية الشحن وغيره',
};
