export const LOGIN_ROUTE_NAME = 'login';
export const REGISTER_ROUTE_NAME = 'register';
export const LOGOUT_ROUTE_NAME = 'logout';
export const HOME_ROUTE_NAME = 'home';
export const ABOUT_ROUTE_NAME = 'about';
export const PRODUCTS_ROUTE_NAME = 'products';
export const PRODUCT_ROUTE_NAME = 'product';
export const CONTACT_ROUTE_NAME = 'contact';
export const MY_ACCOUNT_ROUTE_NAME = 'my-account';
export const MY_ORDERS_ROUTE_NAME = '/my-account/my-orders';
export const USER_IDENTITY_ROUTE_NAME = '/my-account/user-identity';
export const MY_ORDERS_HISTORY_ROUTE_NAME = '/my-account/my-orders-history';
export const MY_PROFILE_ROUTE_NAME = '/my-account/my-profile';
export const FAQ_ROUTE_NAME = 'faq';
export const LUBRICANT_DEALERS_ROUTE_NAME = 'lubricant-dealers';
export const USER_HOME_ROUTE_NAME = 'home';
const routeAllies = {
  landing: {
    home: HOME_ROUTE_NAME,
    about: ABOUT_ROUTE_NAME,
    products: PRODUCTS_ROUTE_NAME,
    product: PRODUCT_ROUTE_NAME,
    contact: CONTACT_ROUTE_NAME,
    my_account: MY_ACCOUNT_ROUTE_NAME,
    user_identity: USER_IDENTITY_ROUTE_NAME,
    my_orders: MY_ORDERS_ROUTE_NAME,
    my_orders_history: MY_ORDERS_HISTORY_ROUTE_NAME,
    my_profile: MY_PROFILE_ROUTE_NAME,
    faq: FAQ_ROUTE_NAME,
    lubricant_dealers: LUBRICANT_DEALERS_ROUTE_NAME,
    resetpassword: 'reset-password/:token/:email',
  },
  auth: {
    login: LOGIN_ROUTE_NAME,
    register: REGISTER_ROUTE_NAME,
    logout: {
      path: `/${LOGOUT_ROUTE_NAME}`,
      name: LOGOUT_ROUTE_NAME
    }
  },
  user: {
    profile: 'profile',
    index: 'user-index',
  },
};
// console.log(routeAllies);
export default routeAllies;
